import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hiring a Good Operator Feels Like Magic`}</h1>
    <p><strong parentName="p">{`Every business owner needs an operator.`}</strong></p>
    <p>{`This post is all about the in's and out's of how I approached finding my
operator for the fund and why it's so important.`}</p>
    <p>{`Blush, use these frameworks and strategies to find your own operator or reverse
engineer it to land your dream gig....`}</p>
    <p>{`Why do i care about this? Because when you have a good operator, it feels like
magic. You wake up and every day the business is getting better. All the stuff
you hated to do or sucked at doing - this person is actually good at.`}</p>
    <p><strong parentName="p">{`I want that with my fund. I’ll still be the main person taking pitches and
deciding what companies to invest in - but an operator can help with all of the
other stuff:`}</strong></p>
    <ul>
      <li parentName="ul">{`Sourcing deals`}</li>
      <li parentName="ul">{`Vetting / Research / Diligence`}</li>
      <li parentName="ul">{`Closing / all the paperwork of investing`}</li>
      <li parentName="ul">{`Creating content to build the brand`}</li>
    </ul>
    <p><strong parentName="p">{`Here are my three rules for getting great operators:`}</strong></p>
    <ol>
      <li parentName="ol">{`Be really clear about what i want`}</li>
      <li parentName="ol">{`Make sure incentives are aligned`}</li>
      <li parentName="ol">{`And the most important rule…. Do something really great - because great
people want to work on great projects. If your project is lame, it’s really
hard to attract a great operator.`}</li>
    </ol>
    <p>{`Hiring an operator takes time. You have to write the job posting, spread the
word, look through 100+ resumes, do 15+ phone screens, do reference checks etc…`}</p>
    <p>{`To get the ball rolling, i had to write the job post and go to Twitter. Most
people go to job boards like indeed.com or whatever, and they post the most
generic job descriptions.`}</p>
    <p>{`It’s crazy! Companies should think of job postings like guys think about Tinder.
You don’t want to look like every-other-guy. You want to be hot and witty and
charming and have 3 pictures that show your different sides (shirtless, with
friends, and doing some adventurous hobby or cooking).`}</p>
    <p>{`So I put out this
`}<a parentName="p" {...{
        "href": "https://twitter.com/ShaanVP/status/1307113322567868416"
      }}>{`tweet storm`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1070px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAezopQf/xAAYEAACAwAAAAAAAAAAAAAAAAAAAREgIf/aAAgBAQABBQJYTX//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAQAGPwKL/8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAEDGx/9oACAEBAAE/IRArqrcyDK//2gAMAwEAAgADAAAAEJDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAQACAQUAAAAAAAAAAAAAAREAIRAxUXGBsf/aAAgBAQABPxC/YFlr64R1L7MAKNzsNeMd24//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hiring Operator Tweet 1",
          "title": "Hiring Operator Tweet 1",
          "src": "/static/ac52ae7a9ac4eda6ecc6976d1a5b6220/8d937/operator-tweet-1.jpg",
          "srcSet": ["/static/ac52ae7a9ac4eda6ecc6976d1a5b6220/a6407/operator-tweet-1.jpg 400w", "/static/ac52ae7a9ac4eda6ecc6976d1a5b6220/a24e6/operator-tweet-1.jpg 800w", "/static/ac52ae7a9ac4eda6ecc6976d1a5b6220/8d937/operator-tweet-1.jpg 1070w"],
          "sizes": "(max-width: 1070px) 100vw, 1070px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The first line needs to be a hook / tease. I want you to be interested (putting
up the bat signal? About what? What does that mean?)`}</p>
    <p>{`The second line is about YOU (not me). 9 out of 10 people would start talking
about themselves. Instead, I am trying to catch the eye of someone who wants to
be a startup investor someday.`}</p>
    <p>{`Lastly is the promise: “I’m going to give a shot to someone who’s hungry and
wants to learn by doing”`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1082px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "41%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB26Cgf//EABYQAQEBAAAAAAAAAAAAAAAAAAEQEv/aAAgBAQABBQLIX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAABAh/9oACAEBAAY/Ao//xAAXEAADAQAAAAAAAAAAAAAAAAAAARFB/9oACAEBAAE/IcKaKiP/2gAMAwEAAgADAAAAEAAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBgbH/2gAIAQEAAT8QLgwVw3q2zob+Tht+z//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hiring Operator Tweet 2",
          "title": "Hiring Operator Tweet 2",
          "src": "/static/2753357a2a25d12d7bc912f3ec501a77/6bc47/operator-tweet-2.jpg",
          "srcSet": ["/static/2753357a2a25d12d7bc912f3ec501a77/a6407/operator-tweet-2.jpg 400w", "/static/2753357a2a25d12d7bc912f3ec501a77/a24e6/operator-tweet-2.jpg 800w", "/static/2753357a2a25d12d7bc912f3ec501a77/6bc47/operator-tweet-2.jpg 1082w"],
          "sizes": "(max-width: 1082px) 100vw, 1082px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This is all about credibility. I have to brag a little (raised a few million
bucks) and then again back to YOU. What do YOU get out of this? (learn the
investing process from A to Z)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1072px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB26Cgf//EABUQAQEAAAAAAAAAAAAAAAAAABEQ/9oACAEBAAEFAgv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAQAGPwKP/8QAGxAAAAcBAAAAAAAAAAAAAAAAAAEQETFBYYH/2gAIAQEAAT8hrh0OeJ//2gAMAwEAAgADAAAAEHPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBAAAgICAwAAAAAAAAAAAAAAAREAITFRQYGh/9oACAEBAAE/EAFDayXO4MK9QNWup//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hiring Operator Tweet 3",
          "title": "Hiring Operator Tweet 3",
          "src": "/static/77f73ea0dea76ccbbe3db373d5ddae21/58c22/operator-tweet-3.jpg",
          "srcSet": ["/static/77f73ea0dea76ccbbe3db373d5ddae21/a6407/operator-tweet-3.jpg 400w", "/static/77f73ea0dea76ccbbe3db373d5ddae21/a24e6/operator-tweet-3.jpg 800w", "/static/77f73ea0dea76ccbbe3db373d5ddae21/58c22/operator-tweet-3.jpg 1072w"],
          "sizes": "(max-width: 1072px) 100vw, 1072px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Again - what do YOU get? More detail this time. You get 4 years of experience in
1 year (sweet), and you get a piece of my carry and no salary (trying to be
ultra clear about `}{`$`}{`$`}{`).`}</p>
    <p>{`Also - it’s not too good to be true. You have to give something back. You give
your nights/weekends to hustle for the fund.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1096px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.500000000000004%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB26Cgf//EABgQAAIDAAAAAAAAAAAAAAAAAAARAQIQ/9oACAEBAAEFAoqhZ//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABkQAQACAwAAAAAAAAAAAAAAAAEAETFhcf/aAAgBAQABPyEBQtG50wxP/9oADAMBAAIAAwAAABDwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQACAgMAAAAAAAAAAAAAAAEAESExcZGh/9oACAEBAAE/EBImAtL3L69oUot8z//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hiring Operator Tweet 4",
          "title": "Hiring Operator Tweet 4",
          "src": "/static/300cf30d7ea81ea45744da2c64c8554f/8bffe/operator-tweet-4.jpg",
          "srcSet": ["/static/300cf30d7ea81ea45744da2c64c8554f/a6407/operator-tweet-4.jpg 400w", "/static/300cf30d7ea81ea45744da2c64c8554f/a24e6/operator-tweet-4.jpg 800w", "/static/300cf30d7ea81ea45744da2c64c8554f/8bffe/operator-tweet-4.jpg 1096w"],
          "sizes": "(max-width: 1096px) 100vw, 1096px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`OK this is `}{`*`}{`chefs kiss`}{`*`}{` if I do say so myself.. Most job posts lead with “you
must have X years of experience”. I go the other way. I try to describe a
lifestyle or a personality trait first. When people read this, it resonates in a
way that “7 years of experience and a bachelors degree” doesn’t.`}</p>
    <p>{`I spent a lot of time coming up with variations of phrases / ways to say these
tweets. This shit looks casual - but behind the curtain, I sweat these details
.. and then try to make it look casual & off the cuff.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1084px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdu4SD//xAAWEAADAAAAAAAAAAAAAAAAAAAQERL/2gAIAQEAAQUClH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAQAGPwKP/8QAGBAAAgMAAAAAAAAAAAAAAAAAETEAARD/2gAIAQEAAT8hoiCLP//aAAwDAQACAAMAAAAQ88//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAAMBAAAAAAAAAAAAAAABEQAQIZH/2gAIAQEAAT8QFJFKjXq1wvAHuv/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hiring Operator Tweet 5",
          "title": "Hiring Operator Tweet 5",
          "src": "/static/dff3125e6aee90110417238f3f76309b/3e98e/operator-tweet-5.jpg",
          "srcSet": ["/static/dff3125e6aee90110417238f3f76309b/a6407/operator-tweet-5.jpg 400w", "/static/dff3125e6aee90110417238f3f76309b/a24e6/operator-tweet-5.jpg 800w", "/static/dff3125e6aee90110417238f3f76309b/3e98e/operator-tweet-5.jpg 1084w"],
          "sizes": "(max-width: 1084px) 100vw, 1084px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`OK why say this?`}</p>
    <p>{`Well, I am offering `}{`$`}{`0 salary. So normally, that would put you at the back of
the line. My only asset is that someone here is going to get real, tangible
experience & responsibility.`}</p>
    <p>{`So I’m trying to give them a framework. Make them want to be a driver (or at
least riding shotgun). Rather than a backseat passenger or in the trunk, just
along for the ride (jobs at bigger firms).`}</p>
    <p>{`Also - by speaking in unique ways, and offering these frameworks - I’m selling
myself too as someone interesting to work with. Someone you can learn from.`}</p>
    <p>{`You know how they say: “people don’t leave jobs, they leave managers” - it’s the
same thing in reverse. People don’t join jobs, they join leaders`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1072px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "37.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB26Cgf//EABUQAQEAAAAAAAAAAAAAAAAAABEQ/9oACAEBAAEFAgv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAYEAEAAwEAAAAAAAAAAAAAAAABABAhEf/aAAgBAQABPyEOAwh2v//aAAwDAQACAAMAAAAQc8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAwADAAAAAAAAAAAAAAABABEhMUFx/9oACAEBAAE/ECRwQGrkHRr2F1vM/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hiring Operator Tweet 6",
          "title": "Hiring Operator Tweet 6",
          "src": "/static/ea362d6e112ddb7403ef92383b042f82/58c22/operator-tweet-6.jpg",
          "srcSet": ["/static/ea362d6e112ddb7403ef92383b042f82/a6407/operator-tweet-6.jpg 400w", "/static/ea362d6e112ddb7403ef92383b042f82/a24e6/operator-tweet-6.jpg 800w", "/static/ea362d6e112ddb7403ef92383b042f82/58c22/operator-tweet-6.jpg 1072w"],
          "sizes": "(max-width: 1072px) 100vw, 1072px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This is my version of “values”.`}</p>
    <p>{`Company values are usually boring as hell. I tried to phrase mine in a way
that’s catchy/interesting - and everyone applying will know what I care about.
For the right person, this is music to their ears.`}</p>
    <p>{`For the wrong person (someone who is very traditional, structured, regimented),
this job posting should repel them. I want my job post to turn off at least 30%
of people who read it, and for 30% they should feel like this is their life’s
calling.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1090px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdmwJA//xAAWEAADAAAAAAAAAAAAAAAAAAACEBH/2gAIAQEAAQUCGP8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAEBAAY/Aoz/AP/EABoQAAEFAQAAAAAAAAAAAAAAAAEAEBEhcYH/2gAIAQEAAT8hCFB1Drf/2gAMAwEAAgADAAAAEHPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQEAAwEAAAAAAAAAAAAAEQEAMUFhgf/aAAgBAQABPxBU1vGq+3Qd/TpCb//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hiring Operator Tweet 7",
          "title": "Hiring Operator Tweet 7",
          "src": "/static/37f4237ceb5dee2d1871c355d627e6c0/2a1df/operator-tweet-7.jpg",
          "srcSet": ["/static/37f4237ceb5dee2d1871c355d627e6c0/a6407/operator-tweet-7.jpg 400w", "/static/37f4237ceb5dee2d1871c355d627e6c0/a24e6/operator-tweet-7.jpg 800w", "/static/37f4237ceb5dee2d1871c355d627e6c0/2a1df/operator-tweet-7.jpg 1090w"],
          "sizes": "(max-width: 1090px) 100vw, 1090px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`OK my last piece of job posting judo - I call out who I want to apply. An
outsider, the underdog.`}</p>
    <p>{`And I tag a friend who has a good following, which increases the likelihood that
she’ll RT and cast a wider net for me!`}</p>
    <p><strong parentName="p">{`The result?`}</strong></p>
    <p>{`200+ DMs, and even some creative applications..I’ll share my top 4 favorite ways
people applied:`}</p>
    <ol>
      <li parentName="ol">{`The person who sent their spotify playlist & used song titles to make their
case…`}</li>
      <li parentName="ol">{`Person who venmo’d me 2 cents`}</li>
      <li parentName="ol">{`The person who made a website for the fund`}</li>
      <li parentName="ol">{`The person who did a full notion doc on why i should hire them`}</li>
    </ol>
    <p><strong parentName="p">{`Once i make the hire, i'll go overboard with onboarding:`}</strong></p>
    <p>{`I LOVE going overboard during the onboard. I remember when I got a job, I was
excited, but for the company it seemed like just another day. They’d hand me my
badge, tell me where to sit, and I kind of figured it out from there.`}</p>
    <p>{`At my last company, I created custom packets for every new hire. I stole this
from my basketball coach in high school.`}</p>
    <p>{`When we came back for our senior year, he handed each of us a big envelope and
said - open this when you get home.`}</p>
    <p>{`I opened it up and it was “my hopes and dreams for your senior year on
varsity” - and it laid out what my role on the team is. What my stats can be.
What areas I will get better in. etc.. I was blown away. I felt like this guy
really cared about me and understood me and wanted what’s best for me.`}</p>
    <p>{`So I do that for people I work with too.`}</p>
    <p><strong parentName="p">{`Here’s an example of one I made for someone who came to work for me at Bebo
(`}<a parentName="strong" {...{
          "href": "https://docsend.com/view/i459ryqrf8sm7ed3"
        }}>{`click here to get it`}</a>{`)`}</strong></p>
    <p>{`When someone joins my team, I want them to have clarity on their role, and
excitement that they aren’t coming to just some job.`}</p>
    <p>{`This is a special place with special people doing special things.`}</p>
    <p><strong parentName="p">{`I use a 3-Week trial`}</strong></p>
    <p>{`Interviews are basically a competition to see who can sound better, not do the
actual job!`}</p>
    <p>{`That’s why i always try to use a 3-week trial for anyone i hire. Basically, we
both agree that it makes sense to see how 3 weeks go before committing
long-term. This helps us both figure out if we like working together - i call it
the "3-week trial" (clever, huh?)`}</p>
    <p><strong parentName="p">{`Hopefully this helps you think a little differently about how to find an
operator for that biz you’re running or reverse engineer it to get the
opportunity you’ve been waiting for`}</strong></p>
    <p>{`until tomorrow.`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docsend.com/view/i459ryqrf8sm7ed3',
        label: 'Bebo custom onboarding packet'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/ShaanVP/status/1307113322567868416',
        label: "Shaan's rolling fund hiring tweet storm"
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.good_artists_copy_great_artists_steal.label,
      slug: slugify(postMap.good_artists_copy_great_artists_steal.slug)
    }} nextButton={{
      text: postMap.behind_the_scenes_of_the_first_fund_lp_update.label,
      slug: slugify(postMap.behind_the_scenes_of_the_first_fund_lp_update.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      